import React from "react";
import SEO from "../components/global/Seo";
import Layout from "../components/layout/Layout";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Masonry from "react-masonry-css";

export default () => {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "herbata.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImg2: file(relativePath: { eq: "herbata2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      richmont: file(relativePath: { eq: "producenci/richmont.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sirWilliams: file(relativePath: { eq: "producenci/sirWilliamsTea.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sirWilliamsRoyalTaste: file(
        relativePath: { eq: "producenci/sirWilliamsRoyalTaste.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      klienci: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "klienci" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout pageTitle="Herbaty">
      <SEO title="Herbaty" />
      <div className="row align-items-center section-padding-xs paragraph-content">
        <div className="col-md-6">
          <div className="text-box-padding-xs-right">
            <h2>Twój dostawca herbaty</h2>
            <strong>
              Posiadamy w ofercie herbaty, które doceni każdy koneser tego
              aromatycznego napoju.
            </strong>
            <p>
              Jesteśmy dystrybutorem marki Richmont oraz Sir William's na
              polskim rynku. Proponujemy wysokiej jakości herbaty, bez
              sztucznych aromatów i barwników.
            </p>
            <p>
              Skontaktuj się z nami, nasi konsultanci nie mogą się doczekać
              nowych wyzwań związanych z herbatą.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <Img fluid={query.bannerImg.childImageSharp.fluid} alt="Banner" />
        </div>
      </div>
      <div className="row paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">Richmont</h3>
          <Img
            className="producer-logo"
            fluid={query.richmont.childImageSharp.fluid}
            alt="Sir Williams"
          />
        </div>
        <div className="col-md-8 offset-md-1">
          <p>
            Wyjątkowa herbata Richmont to bez wątpienia jedna z najbardziej
            rozchwytywanych marek herbat z wysokiej półki. Jej niepowtarzalny
            smak i bogaty aromat już od dawna cieszy się ogromną popularnością
            zarówno w domowym zastosowaniu, jak i w kawiarniach czy
            restauracjach.
          </p>
          <p>
            Te oryginalne herbaty, których smaku nie można pomylić z żadnym
            innym, zostały starannie przygotowane z najlepszych mieszanek suszu
            charakteryzujących się cudownym smakiem i bogatym aromatem. Wszystko
            to sprawia, że właśnie ten produkt stanowi idealną propozycję, która
            na pewno urzeknie prawdziwych miłośników wyjątkowego naparu w
            najlepszej odsłonie. Marka Richmont od początku oznaczała przede
            wszystkim najwyższą jakość.
          </p>
        </div>
      </div>
      <div className="row paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">
            Sir William's / Sir William's Royal Taste
          </h3>
          <Img
            className="producer-logo"
            fluid={query.sirWilliams.childImageSharp.fluid}
            alt="Sir William's"
          />
          <Img
            className="producer-logo"
            fluid={query.sirWilliamsRoyalTaste.childImageSharp.fluid}
            alt="Sir William's Royal Taste"
          />
        </div>
        <div className="col-md-8 offset-md-1">
          <p>
            Herbaty Sir William's to połączenie tradycji i dobrego smaku.
            Wszystkie gatunki są efektem wieloletnich poszukiwań, testów,
            konsultacji i pogłębiania wiedzy o najlepszych herbatach.
          </p>
          <p>
            Najsłynniejsze i najbardziej cenione odmiany są jak diamenty. Ich
            odnalezienie to sztuka, która udała się niewielu. Doskonała herbata
            to bowiem nie tylko smak lecz również kultura jej spożywania, od
            zaparzenia po sposób serwowania.
          </p>
          <p>
            Różne gatunki i kompozycje smakowe mają swoje korzenie w kulturach
            całego świata. Sir William's pozwala rozsmakować się w magii tych
            złocistych aromatycznych naparów pachnących orientem, luksusem i
            przyjemnością.
          </p>
        </div>
      </div>
      <div className="row align-items-center justify-content-end section-padding-xs image-text-box paragraph-content">
        <div className="box-image">
          <Img fluid={query.bannerImg2.childImageSharp.fluid} alt="Banner" />
        </div>
        <div className="col-md-8 col-lg-6">
          <div className="box-text">
            <h3>Rekomendują nas między innymi</h3>

            <Masonry
              breakpointCols={4}
              className="masonry-grid mt-4"
              columnClassName="masonry-grid_column"
            >
              {query.klienci.edges.map((edge, i) => {
                return (
                  <div key={i}>
                    <Img
                      key={i}
                      fluid={edge.node.childImageSharp.fluid}
                      alt="Klient logo"
                    />
                  </div>
                );
              })}
            </Masonry>
          </div>
        </div>
      </div>
    </Layout>
  );
};
